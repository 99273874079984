@import '../../../../../../assets/scss/non-compilable-tools';

ultra-token-preview-body {
  display: block;

  .background-image {
    width: 80%;
    right: 0;
    position: absolute;
    height: 432px;
    background-position: center;
    background-size: cover;
    border-radius: 4px;

    &:before {
      background: radial-gradient(100% 100% at 100% 0%, rgba(60, 56, 70, 0) 0%, $grey 100%),
        linear-gradient(270.02deg, rgba(60, 56, 70, 0.3) 50%, $grey 99.98%),
        linear-gradient(80.93deg, $grey 0%, rgba(60, 56, 70, 0.3) 100%), rgba(137, 106, 226, 0.15);
      width: 100%;
      height: 432px;
      content: '';
      position: absolute;
      border-radius: 4px;
    }
  }

  .preview-content-actions {
    right: 24px;
    bottom: 24px;

    .preview-price {
      @include button-1-bold;
      height: 40px;
      min-width: 120px;
      color: $white;
      background-color: $ultra-purple;
      padding: remy(4);

      &__purchased {
        min-width: 150px;
      }

      &__purchased:hover span {
        display: none;
      }

      &__purchased:hover:before {
        content: 'Go To Library';
        padding: remy(6) remy(12);
      }

      &__free:hover > * {
        display: none;
      }

      &__free:hover:before {
        content: 'Get';
      }

      &__currency:hover > * {
        display: none;
      }

      &__currency:hover:before {
        content: 'Buy Now';
      }

      &--empty {
        background-color: $white-alpha-1;
      }
    }
  }
  .btn[disabled] {
    border: 0;
  }
  .tab-header {
    background: $grey-medium;
    margin-top: 2px !important;
    border-radius: 0 0 4px 4px;
  }
}

.uniq-backdrop {
  background-color: rgba($grey-dark-medium, 0.8) !important;
  backdrop-filter: blur(3px);
  opacity: 1 !important;
}

// todo GP-21709 get rid of
.cdk-overlay-container {
  z-index: 1001 !important;
}
