@import '../../../../../../assets/scss/non-compilable-tools';

ultra-id-card-sell {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light-2;

    &:last-child {
      border-bottom: none;
    }
  }

  .nontradable-container {
    min-height: 288px;
    padding: 8px;
  }

  .id-card-sell {
    &-header {
      padding: remy(11) remy(12);

      p {
        font-size: 10px;
        line-height: 20px;
        color: $white-alpha-7;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }

    &-price-form {
      min-height: remy(70);
      padding: remy(8) remy(12);

      p {
        color: $white;
        font-weight: 800;
        font-size: remy(12);
        line-height: remy(20);
      }

      .price-form-field {
        width: remy(128);

        input {
          height: remy(38);
          text-align: right;
        }

        i {
          background-color: $white-50;
        }
      }
    }

    &-fee {
      min-height: remy(94);
      background-color: $grey-dark-1;
      padding: remy(0) remy(12);
      flex-direction: column;

      .fee-reduced-row {
        height: 32px !important;
      }

      .fee-type {
        width: 100%;
        height: remy(48);
        border-bottom: 1px solid $grey-light-2;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
        }

        .fee-name {
          font-size: 10px;
          line-height: 16px;
          color: $white-alpha-7;
        }

        .fee-results {
          display: flex;
          font-size: 10px;
          line-height: 16px;
          color: $white-50;
        }

        .fee-amount {
          width: remy(128);
          display: flex;
          justify-content: flex-end;

          .amount {
            color: $white;
          }
        }
      }
    }

    &-total {
      padding: remy(8) remy(8) remy(8) remy(12);

      p {
        color: $white;
        font-weight: 800;
        font-size: 12px;
        line-height: 20px;
      }

      .total-amount {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: remy(128);
        max-width: remy(256);
        min-height: remy(55);
        border-radius: remy(4);
        font-size: remy(16);
        line-height: remy(24);
        padding: remy(8);
        color: $ultra-purple-light;
        background-color: $ultra-purple-light-transparent;
      }
    }

    &-action {
      padding: remy(8);
    }
  }

  .dropdown-toggle {
    width: remy(72);
    height: remy(54);
  }

  .amount-tooltip {
    &-content {
      width: remy(252);
      font-size: remy(12);
      color: $white-alpha-7;
    }
  }

  .marketplace-tooltip {
    .tooltip-inner {
      background-color: $grey-dark-4;
    }

    .tooltip-arrow:before {
      top: -1px;
      border-top-color: $grey-dark-4;
      border-bottom-color: transparent;
    }

    .tooltip-arrow::after {
      content: none;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      color: $white-75;
      margin: 0;
    }

    ul {
      margin: 0;
    }

    li {
      font-size: 12px;
      line-height: 20px;
      color: $white-75;
      position: relative;
      padding: 0 0 0 12px;

      &::before {
        content: '•';
        color: $white-75;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .price-tooltip {
    min-width: 270px;
  }

  .refresh-timer {
    width: 16px;
  }
}
