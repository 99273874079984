@import '../../../../../assets/scss/non-compilable-tools';

ultra-notification-message {
  display: flex;
  border-radius: $border-radius;
  position: relative;

  > div {
    padding: remy(8) remy(12);
  }

  .message {
    min-height: remy(24);
    font-size: remy(12);

    a {
      color: $red-light;
      margin-left: 4px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .icon-main[class] {
    display: none;
  }

  &.notification-info,
  .notification-info {
    color: $ultra-purple-light;
    background-color: $purple-dark-transparent;

    .icon-info[class] {
      display: block;
    }

    a {
      color: $ultra-purple-light;
    }

    .icon {
      background-color: $ultra-purple-light;
    }
  }

  &.notification-error,
  .notification-error {
    color: $red-light;
    background-color: $red-light-transparent;

    .icon-triangle-alert[class] {
      display: block;
    }

    .icon {
      background-color: $red-light;
    }
  }

  &.notification-success,
  .notification-success {
    color: $light-green;
    background-color: $green-light-transparent;

    .icon-check-default[class] {
      display: block;
    }

    .icon {
      background-color: $light-green;
    }
  }

  &.notification-warning,
  .notification-warning {
    color: $orange-light;
    background-color: $orange-light-transparent;

    .icon-triangle-alert[class] {
      display: block;
    }

    .icon {
      background-color: $orange-light;
    }
  }

  .icon-cross-sm {
    position: absolute;
    right: remy(12);
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}
