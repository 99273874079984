@import '../../../../../assets/scss/non-compilable-tools';

$ultra-header-row-height: 40px;
$ultra-row-height: 64px;
$ultra-row-horizontal-padding: 15px;
$border-color: $grey-dividers;

/**
 * Flex-based table structure
 */
ultra-table {
  display: block;
  background-color: $grey-dark-2-standard-bg;
  color: $white-alpha-7;
}

ultra-row,
ultra-footer-row {
  min-height: $ultra-row-height;
}

ultra-row,
ultra-header-row,
ultra-footer-row {
  display: flex;
  // Define a border style, but then widths default to 3px. Reset them to 0px except the bottom
  // which should be 1px;
  border-width: 0;
  align-items: stretch;
  box-sizing: border-box;

  &::after {
    display: inline-block;
    min-height: inherit;
    content: '';
  }
}

ultra-header-row {
  border-bottom-width: 1px;
  border-style: solid;
  border-color: $border-color;
  min-height: $ultra-header-row-height;
}

ultra-row:last-child {
  border: 0;
}

ultra-row:hover {
  background-color: $grey-medium;
  border-radius: $border-radius;
}

ultra-cell:first-of-type,
ultra-header-cell:first-of-type,
ultra-footer-cell:first-of-type {
  padding-left: $ultra-row-horizontal-padding;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: $ultra-row-horizontal-padding;
  }
}

ultra-cell:last-of-type,
ultra-header-cell:last-of-type,
ultra-footer-cell:last-of-type {
  padding-right: $ultra-row-horizontal-padding;
  border-right-width: 0;
  [dir='rtl'] & {
    padding-right: 0;
    padding-left: $ultra-row-horizontal-padding;
  }
}

ultra-cell,
ultra-header-cell,
ultra-footer-cell {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: 100%;
  border-width: 0;
  justify-content: flex-start;
  padding: 0 15px;
}

ultra-header-cell {
  span {
    display: block;
    position: relative;
    height: 16px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    &:not(.mat-sort-header-arrow):before {
      content: '';
      position: absolute;
      top: 0;
      left: -15px;
      width: 1px;
      height: 100%;
      background-color: $border-color;
    }
  }
}

ultra-cell.align-left {
  justify-content: start;
}
/**
 * Native HTML table structure
 */
ultra-table,
table[ultra-table] {
  border-spacing: 0;
}

tr.ultra-header-row {
  height: $ultra-header-row-height;
}

tr.ultra-row,
tr.ultra-footer-row {
  height: $ultra-row-height;
}

th.ultra-header-cell {
  text-align: left;

  [dir='rtl'] & {
    text-align: right;
  }
}

ultra-header-cell.ultra-header-cell {
  font-size: 16px;
}

th.ultra-header-cell,
td.ultra-cell,
td.ultra-footer-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

th.ultra-header-cell:first-of-type,
td.ultra-cell:first-of-type,
td.ultra-footer-cell:first-of-type {
  padding-left: $ultra-row-horizontal-padding;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: $ultra-row-horizontal-padding;
  }
}

th.ultra-header-cell:last-of-type,
td.ultra-cell:last-of-type,
td.ultra-footer-cell:last-of-type {
  padding-right: $ultra-row-horizontal-padding;

  [dir='rtl'] & {
    padding-right: 0;
    padding-left: $ultra-row-horizontal-padding;
  }
}
