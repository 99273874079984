@import '../../../../../../assets/scss/non-compilable-tools';

:host {
  border-radius: 6px;
}

ultra-uniq-factory-detail {
  box-shadow: 0 remy(11) remy(15) $black-alpha-2, 0 remy(9) remy(46) $black-alpha-12,
    0 remy(24) remy(38) $black-alpha-12;
}

.uniq-factory-detail-overlay {
  ultra-dialog-container {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
