@import '../../../../../../../assets/scss/non-compilable-tools';

.lazy-image {
  animation-duration: 0.75s;
  animation-fill-mode: both;

  &__wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;

    &.full-width {
      img {
        object-fit: cover;
      }
    }

    &.limit-blur-bigscreen {
      @include media-breakpoint-up('xl') {
        img {
          max-width: 80%;
          left: 10%;
          height: auto;
        }
        &:after,
        &:before {
          top: 0;
          width: 25%;
          height: 100%;
          content: '';
          position: absolute;
        }
        &:after {
          right: 10%;
          background: linear-gradient(270deg, $grey-dark 0%, rgba(255, 255, 255, 0) 100%);
        }

        &:before {
          left: 10%;
          background: linear-gradient(90deg, $grey-dark 0%, rgba(255, 255, 255, 0) 100%);
          z-index: 2;
        }
      }
    }

    &.limit-blur {
      img {
        width: 100%;
        left: 0;
        height: auto;
      }
      &:after,
      &:before {
        top: 0;
        width: 25%;
        height: 100%;
        content: '';
        position: absolute;
      }
      &:after {
        right: 0;
        background: linear-gradient(270deg, $grey-dark 0%, rgba(255, 255, 255, 0) 100%);
      }

      &:before {
        left: 0;
        background: linear-gradient(90deg, $grey-dark 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
      }
    }

    &.video-preview {
      position: relative;
      height: 100%;
    }
  }

  &-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  &.static {
    position: static;
  }

  &.no-alt-icon {
    text-indent: -10000px;
  }
  &.fading {
    animation-name: fadeIn;
  }
}

.height-auto {
  height: auto !important;
}

.position-unset {
  position: unset !important;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
  width: auto;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
