$nav-height: 50px;
$search-navbar-height: 56px;

$min-desktop-width: 992px;
$large-screen: 1400px;

$transition-time: 0.3s ease;

// Ultra Wallet Extension variables
$wallet-ext-body-margin: remy(12);

// Ultra Marketplace App Variables
$uniq-view-layout-max-width: 1728px;
