@import '../../../../../../assets/scss/non-compilable-tools';

$border-color: $grey-dividers;
$success-color: $green-light;
$game-active-color: $green-light-transparent-hover;
$beta-active-color: rgba($yellow-light, 0.4);
$background: rgba(255, 234, 167, 0.1);

ultra-id-card-content {
  display: block;
  padding: 0 24px;

  .scroll-container {
    max-height: 325px;

    & > .ps {
      max-height: 325px;
    }
  }

  .branch {
    background: $grey-dark-dk-alpha-38;
    display: flex;
    padding: 4px 8px;
    opacity: 0.5;
  }

  .game-active {
    background: $game-active-color;
    color: $success-color;
    opacity: 1;

    & > i {
      background: $success-color;
    }
  }

  .beta-active {
    background: rgba(255, 193, 7, 0.5);
    color: #ffeaa7;
    opacity: 1;

    & > i {
      background: #ffeaa7;
    }
  }

  .icon-check {
    background: $ultra-purple-light !important;
  }

  .language-header {
    display: flex;
    align-items: center;
    height: 32px;
    background: $white-alpha-05;

    & > span {
      text-transform: none;
      font-weight: 400;
      letter-spacing: 0.25px;
    }
  }

  .token-content {
    height: 120px;
    width: 100%;
    background: $grey-dark-dk-alpha-38;
  }

  .language-cell {
    padding-left: 0;
    padding-right: 32px;
  }

  ultra-table {
    padding: 0 16px;
  }

  ultra-header-cell {
    padding: 8px 15px;
  }

  ultra-row {
    border-bottom: 1px solid $border-color;
    pointer-events: none;
    min-height: 40px;
  }

  .token-type {
    background-color: $white-alpha-05;
    height: 32px;
    min-width: 78px;
    padding: 0 8px;
  }
}
