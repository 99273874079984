@import '../../../../../../assets/scss/non-compilable-tools';

.background-container {
  width: 100%;
  height: 100%;
  background-color: $grey-light-1;
}

.animation-container {
  position: sticky;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  overflow: hidden;
  background: $grey-light-1;
}

.skeleton-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  animation: skeletonScreenAnimation 1s infinite;
}

.skeleton-screen {
  @include syncLoadingScreen();
}

.xs-background-skeleton .background-container .skeleton-screen-container {
  animation-duration: 1.8s;
  .skeleton-screen {
    width: 15%;
    filter: blur(6px);
  }
}

.md-background-skeleton .background-container .skeleton-screen-container {
  animation-duration: 1.5s;
  .skeleton-screen {
    width: 10%;
    filter: blur(10px);
  }
}

.xl-background-skeleton {
  &.game-detail {
    .background-container {
      background-color: $white-10;
    }
  }

  .background-container .skeleton-screen-container {
    animation-duration: 1.3s;
    .skeleton-screen {
      filter: blur(14px);
    }
  }
}

@keyframes skeletonScreenAnimation {
  0% {
    transform: skewX(-6deg) translateX(-15%);
  }
  100% {
    transform: skewX(-6deg) translateX(125%);
  }
}
