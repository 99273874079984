@import '../../../../../../assets/scss/non-compilable-tools';

ultra-tag-input {
  width: 100%;
  display: flex;
  min-height: 80px;
  flex-direction: column;
  padding: 0 0 20px;
  position: relative;

  .tag-input {
    display: flex;
    cursor: text;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: remy(15);
    position: relative;
    background: $black-alpha-2;
    border-radius: $border-radius;
    border: 1px solid $white-alpha-1;
    min-height: 80px;
    transition: border-color 0.3s ease;

    &.invalid input {
      color: $red-light;
    }

    &:hover:not(.focused) {
      border-color: $white-alpha-5;

      .tags-left {
        color: $white-alpha-5;
      }
    }

    &.focused {
      border-color: $primary;
    }
  }

  &.ng-invalid {
    .tag-input,
    .tag-input:hover {
      border-color: $red-light !important;
    }
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  .tag-input-control {
    color: $white;
    border: none;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    min-width: 90%;
    max-width: 100%;
    background-color: transparent;

    &::placeholder {
      font-size: 12px;
      color: $white-alpha-3;
    }
  }

  .tags-left {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 11px;
    line-height: 16px;
    color: $white-alpha-3;
    text-transform: uppercase;
  }

  .error-message {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 11px;
    line-height: 16px;
    color: $red-light;
  }
}
