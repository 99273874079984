@import '../../../../../../../assets/scss/non-compilable-tools';

$scroll-height: 200px;

.dropdown {
  &-menu {
    min-width: 100% !important;
  }

  &-select {
    &__btn {
      min-height: 30px;
      cursor: pointer;
      border: 1px solid $white-alpha-7;
      color: $white-alpha-7;

      &:hover:not(.show) {
        border-color: $white;
        color: $white;

        i {
          background-color: $white;
        }
      }

      i {
        background-color: $white-alpha-7;
      }

      &.disabled {
        border-color: $white-alpha-3;
        color: $white-alpha-3;
        pointer-events: none;
        opacity: 1;

        i {
          background-color: $white-alpha-3;
        }
      }

      &.select-lg {
        width: 256px;
      }

      &--collapsible[class][class] {
        @media (width < 1024px) {
          background-color: $white-10;
          border: 0;
          width: 36px;
          font-size: 0;
          min-height: 36px;
          justify-content: center !important; // overwrite bootstrap important
          span,
          .icon-chevron-sm {
            display: none;
          }
        }
      }
    }

    &__item,
    &__btn {
      padding: remy(5) remy(15);

      &.select-sm {
        padding: remy(5) remy(8);
        font-size: remy(12);
      }
    }

    &__item {
      &-disabled {
        background: $white-alpha-25 !important;
        color: $white-alpha-5 !important;
        pointer-events: none;
        touch-action: none;
      }
    }
  }

  &-toggle:after {
    content: none !important;
  }
}

.backdrop {
  top: 0;
}

ultra-select {
  .dropdown-select {
    .scroll-container {
      max-height: $scroll-height;
    }
  }
}
