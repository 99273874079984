@mixin syncLoadingScreen() {
  display: block;
  overflow: hidden;
  width: 10%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) -140.91%,
    rgba(255, 255, 255, 0.3) 43.37%,
    rgba(255, 255, 255, 0) 204.55%
  );
  filter: blur(10px);
}
