@import '../../../../../../../assets/scss/non-compilable-tools';

ultra-checkbox {
  display: inline-block;

  .checkbox {
    display: flex;
    align-items: center;
    position: relative;

    &.bordered {
      border-radius: $border-radius;
      border: 1px solid $white-alpha-1;

      .checkbox__label {
        padding: remy(8) remy(15);
      }
    }

    &.checkbox-group:not(.disabled) {
      &:hover,
      &.active {
        background-color: $grey-medium;

        .checkbox__label-name {
          color: #fff;
        }

        .checkbox__icon {
          background-color: #fff;
        }
      }
    }

    &__label-name {
      color: $white-alpha-7;
      transition: color $transition-time;

      .secondary & {
        color: $white-alpha-25;
      }
    }

    &__icon {
      margin-right: remy(11);
      background-color: $white-alpha-7;
      transition: background-color $transition-time;

      .secondary & {
        background-color: $white-alpha-25;
      }
    }

    &__label {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      position: relative;
      max-width: 100%;

      .check {
        position: relative;
        opacity: 0;
        transform: rotate(-45deg);
        height: 6px;
        width: 10px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        margin: auto;
        top: -1px;
        left: 0;
        transition: opacity $transition-time;

        &--active {
          opacity: 1;
        }

        &__wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: remy(8);
          border-radius: 2px;
          border: 1px solid $white-alpha-7;
          transition:
            background-color $transition-time,
            border-color $transition-time;

          &--active {
            background-color: $ultra-purple;
            border-color: $ultra-purple;
          }

          .secondary & {
            border-color: $white-alpha-25;
          }
        }

        .icon {
          opacity: 0;
          transition: opacity $transition-time;
        }
      }

      &--lg {
        .check__wrapper {
          height: 18px;
          min-width: 18px;
          border-width: 2px;
        }
      }

      &--m {
        .check__wrapper {
          height: 15px;
          min-width: 15px;
          margin-right: remy(10);
        }

        .check {
          transform: scale(0.8) rotate(-45deg);
        }
      }

      &--sm {
        .check__wrapper {
          height: 12px;
          min-width: 12px;
          margin-right: remy(12);
        }

        .check {
          transform: scale(0.7) rotate(-45deg);
        }
      }
    }

    &__input {
      opacity: 0;
      position: absolute;
      display: none;
    }

    &__input:checked + &__label,
    &__label:not(.disabled):hover {
      .check__wrapper {
        border-color: $ultra-purple;
      }

      .icon {
        background-color: $white;
      }
    }

    &.granted-content-checkbox {
      .check__wrapper {
        margin-right: 7px;
      }
    }
  }
}
