@import '../../../../../../assets/scss/non-compilable-tools';

ultra-uniq-preview {
  .price {
    min-width: 106px;
  }

  .preview-typ6e-marker {
    width: 36px;
    height: 36px;
  }

  &.disabled,
  &.disabled:hover {
    display: block;
    cursor: default;

    .preview {
      background-color: $grey-dark-2-standard-bg;
    }

    .btn-primary {
      background-color: $ultra-purple-disabled;
      border-color: $ultra-purple-disabled;
      cursor: default;

      &:hover {
        background-color: $ultra-purple-disabled;
        border-color: $ultra-purple-disabled;
      }
    }

    .factory-name,
    .factory-subname {
      color: $white-50 !important;
    }

    .loader {
      border: 2px solid $ultra-purple-light;
      border-bottom-color: $white-10;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .preview:hover {
      padding: 8px 24px 8px 4px !important;
    }
  }

  .id-card-image {
    width: 80px;
    height: 80px;
  }

  .check-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .icon-uniq-collectible {
    background: $white-alpha-5;
  }

  .lh-16 {
    line-height: 16px;
  }

  .btn-primary.disabled {
    cursor: default;
  }

  .btn-loading {
    min-height: remy(32);
  }

  .min-view-110 {
    min-width: 110px !important;
  }

  @media (width <= 900px) {
    .uniq-sync-reduced-text {
      max-width: 200px;
    }
  }
  @media (width >= 901px) and (width <= 1110px) {
    .uniq-sync-reduced-text {
      max-width: 250px;
    }
  }
}

ultra-id-card-header .preview__inner {
  container-type: inline-size;
  container-name: uniq-preview;
}

@container uniq-preview (max-width: 1200px) {
  ultra-uniq-preview {
    .info-message-detail[class] {
      padding: 8px;
    }
    .info-message-detail__content {
      display: none;
    }
  }
}
