@import '../../../../../../assets/scss/non-compilable-tools';

ultra-id-card-attributes {
  display: block;
  height: 100%;
  padding: 0 24px;

  .content-wrap {
    display: flex;
    min-height: 325px;
  }

  .content-item {
    position: relative;
    flex: 1;
    max-width: 50%;
    max-height: 325px;

    &--left {
      margin-right: 12px;
    }

    &--right {
      margin-left: 12px;
      margin-right: -16px;
    }
  }

  .content-image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset;
    max-width: 100%;
    max-height: 100%;

    &-wrap {
      display: block;
      width: 100%;
      height: 100%;
    }

    & > img {
      object-fit: contain;
      filter: drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.25)) drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14))
        drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12));
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      top: auto;
      left: auto;
    }
  }

  .ps-content {
    height: inherit;
  }

  .attributes-table {
    width: 100%;
    min-height: 100%;
    height: auto;
    border-radius: 4px;
    background-color: $grey-light-1;
    font-size: 12px;

    &__caption {
      padding: 12px 14px 12px;
      border-bottom: 1px solid $grey-light-2;
    }

    &__body {
      padding: 12px;
      flex: 1;
    }

    &__row {
      & + .attributes-table__row {
        margin-top: 8px;
      }
    }

    &__cell {
      width: 50%;
      padding: 8px;
      background-color: $grey-dark-1;

      &:first-child {
        padding-right: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        color: $white-alpha-7;
      }

      &:last-child {
        padding-left: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: right;
      }
    }

    &__no-attributes {
      background-color: $grey-dark-1;
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      flex: 1;
    }
  }

  .scroll-container {
    padding-right: 16px;
    height: 100%;
  }

  .custom-scroll--active .scroll-container {
    padding-right: 12px;
  }
}
