@import '../../../../../../assets/scss/non-compilable-tools';

.uniq-preview-body .divider {
  bottom: 79px;
  top: unset;
  z-index: 1;
}

.preview-content {
  background: $grey-hover;
}
