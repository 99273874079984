@import '../../../../../../assets/scss/non-compilable-tools';

ultra-form-field {
  display: inline-block;
  position: relative;
  width: 100%;

  .form-field {
    &-flex {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    &-label {
      margin: 0;
      font-size: remy(10);
      font-weight: 500;
      line-height: remy(18);
      color: $white-75;
      letter-spacing: remy(1.5);
      text-transform: uppercase;
    }

    &-prefix,
    &-suffix {
      display: flex;
      align-items: center;
    }

    &-prefix {
      margin: 0 remy(8) 0 0;
    }

    &-suffix > * {
      margin: 0 0 0 remy(8);
    }

    &-hint {
      &-wrapper {
        display: flex;
        align-items: center;
        min-height: remy(22);
      }

      &-spacer {
        flex: 1 0 1em;
      }
    }
  }

  &.form-field-type-ultra-input {
    &.error,
    &.error.focused {
      .form-field {
        &-input-container,
        &-input-container:hover {
          border-color: $red-light;
        }
      }

      input {
        caret-color: $red-light;
      }

      // Todo remove it when display error message component will be ready
      .text-danger {
        color: $red-light !important;
      }
    }

    &.disabled,
    &.readonly {
      pointer-events: none;

      &-input-container,
      &-input-container:hover {
        border-color: $white-20;
      }

      input {
        color: $white-50;

        &::placeholder {
          color: $white-20;
        }
      }
    }

    &.focused {
      .form-field {
        &-input-container,
        &-input-container:hover {
          border-color: $ultra-purple-light;
        }
      }
    }

    .form-field {
      &-input-container {
        position: relative;
        border-radius: remy(4);
        border: 1px solid $white-10;
        background: $black-20;
        transition: border-color $animation-time;
        padding: remy(7) remy(8);
        display: flex;
        align-items: center;

        &:hover {
          border-color: $white-50;

          input::placeholder {
            color: $white-50;
          }
        }
      }

      &-infix {
        display: flex;
        width: 100%;
      }
    }

    input,
    textarea {
      padding: 0;
      line-height: remy(16);
      font-size: remy(12);
      width: 100%;
      background-color: transparent;
      border: none;
      color: $white-100;
      caret-color: $ultra-purple-light;

      &::placeholder {
        color: $white-30;
        transition: color $animation-time;
      }
    }

    input {
      height: remy(16);
    }

    textarea {
      resize: none;
      padding: 2px 0;
      margin: -2px 0;
      border: 0;
      box-sizing: content-box;
    }
  }

  ultra-hint,
  .ultra-hint {
    font-size: remy(10);
    line-height: remy(17);
    letter-spacing: remy(1.5);
  }

  .suffix-absolute-position {
    position: absolute;
    top: 7px;
    right: 8px;
  }

  .suffix-absolute-position-scroll {
    position: absolute;
    top: 7px;
    right: 25px;
  }
}
