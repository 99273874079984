@import '../../../../../../assets/scss/non-compilable-tools';

ultra-id-card-overview {
  display: block;
  height: 100%;
  padding: 0 24px;

  .content-overview {
    display: flex;
    min-height: 325px;

    &__title {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
    }

    &__sub-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: $white-alpha-5;
    }

    &--advanced {
      .content-overview__title {
        font-size: 24px;
        line-height: 32px;
      }

      .content-overview__sub-title {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .product-image {
    max-height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset;

    &-wrap {
      display: block;
      width: 100%;
      height: 100%;
    }

    & > img {
      object-fit: contain;
      filter: drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.25)) drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14))
        drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12));
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      top: auto;
      left: auto;
    }
  }

  .content-item {
    position: relative;
    flex: 1;
    max-width: calc(50% - 12px);
    max-height: 325px;

    &--left {
      margin-right: 12px;
    }

    &--right {
      margin-left: 12px;
    }
  }

  .token-description {
    padding-right: 16px;
    max-width: 100%;
    white-space: pre-line;
    overflow: auto;
  }

  .scroll-container {
    padding-right: 24px;
  }

  .external-reference {
    display: inline-flex;

    &__icon {
      margin: 0 0 0 5px;
      background: $primary;
    }

    &:hover {
      color: $ultra-purple-light-hover;

      .external-reference__icon {
        color: $ultra-purple-light-hover;
        background: $ultra-purple-light-hover;
      }
    }
  }

  .product-creator,
  .product-owner {
    text-transform: none;
  }

  .product-creator {
    &__check {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: transparent;
    }
  }

  .product-owner {
    &__avatar {
      padding: 4px;
      background: $grey-light-2;
    }

    &__icon {
      min-width: 12px;
      min-height: 12px;
    }
  }
}
