@import '../../../../../../assets/scss/non-compilable-tools';

// todo GP-21709 get rid of
.cdk-overlay-container {
  z-index: 999;
}

ultra-dialog-container {
  border: 0px;
  box-shadow: none;
  background: transparent;
}

.media-expanded-header {
  justify-content: flex-end;
  padding-top: $nav-height;

  .close-button {
    width: 27px;
    height: 44px;
    background: 0;
    border: 0;
    z-index: 10;

    .icon-cross-24 {
      background-color: $white-alpha-7;

      &:hover {
        background-color: $white;
      }
    }
  }
}

.slide-media-wrapper {
  .media-container {
    img {
      max-width: 100%;
      max-height: auto;

      &:hover {
        filter: drop-shadow(0px 16px 24px $black-alpha-14) drop-shadow(0px 6px 30px $black-alpha-12)
          drop-shadow(0px 8px 10px $black-alpha-2);
      }
    }

    video {
      width: 100% !important;
      height: 100% !important;
    }

    button:not(.youtube-player-placeholder-button) {
      height: 100%;
      width: 100px;
      position: absolute;
      background: 0;
      z-index: 10;
      border: 0;
      transition: $animation-time background;

      @include media-breakpoint-down(md) {
        display: none;
      }

      &:hover {
        .icon {
          opacity: 1;
        }
      }

      &.previous-button {
        display: flex;
        align-items: center;
        justify-content: center;
        left: -100px;

        &:hover {
          background: radial-gradient(100% 50% at 100% 50%, $white-20 0%, rgba($white-100, 0) 100%);
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &.next-button {
        display: flex;
        align-items: center;
        justify-content: center;
        right: -100px;

        &:hover {
          background: radial-gradient(100% 50% at 0% 50%, $white-20 0%, rgba($white-100, 0) 100%);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .icon {
        opacity: 0.7;
      }
    }
  }
}

.media-expanded-pagination {
  margin: 0 auto;

  .pagination-side {
    flex: 1 1 auto;
    padding: 50px 0;
  }

  .pagination-bullet-wrapper {
    width: fit-content;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: center;
    padding: 50px;

    .pagination-bullet {
      width: 4px;
      height: 4px;
      border-radius: 8px;
      margin: 0 4px;
      background-color: $white-alpha-7;
      cursor: pointer;

      &.active {
        background-color: $white;
      }
    }
  }
}
