@import '../../../../../assets/scss/non-compilable-tools';

.ultra-slider {
  position: relative;

  &.theme {
    &-slideshow,
    &-uniq-detail,
    &-uniq-factory-detail,
    &-token-preview,
    &-game-detail {
      .ultra-slider-button-prev {
        left: 0px;
        height: calc(100% + 1px);
        width: 80px;
        display: block;
        background: linear-gradient(90deg, $grey-dark-1 0%, rgba($grey-dark-1, 0) 100%);
        @include media-breakpoint-down('xl') {
          width: 60px;
        }

        .icon {
          margin-right: 47px;
        }
      }

      .ultra-slider-button-next {
        right: 0px;
        height: calc(100% + 1px);
        width: 80px;
        display: block;
        background-image: linear-gradient(90deg, rgba($grey-dark-1, 0) 0%, $grey-dark-1 100%);

        @include media-breakpoint-down('xl') {
          width: 60px;
        }
        .icon {
          margin-left: 37px;
        }
      }
    }

    &-carousel-single-slide {
      .ultra-slider-button {
        display: none;
        height: 48px;
        width: 48px;
        max-height: 48px;
        max-width: 48px;
        background-color: #0000004d;
        border-radius: 4px;
      }

      .ultra-slider-button-prev {
        left: 8px;

        @include media-breakpoint-down('xl') {
          width: 60px;
        }
      }
      .ultra-slider-button-next {
        right: 8px;
        @include media-breakpoint-down('xl') {
          width: 60px;
        }
      }
    }
    &-carousel-single-slide:hover {
      .ultra-slider-button {
        display: block;
        &.hidden {
          display: block !important;
        }
      }
    }

    &-carousel-slideshow {
      .ultra-slider-button {
        display: none;
      }
    }
  }
}

.ultra-slider-button {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  transform: translateY(-50%);
  background: 0;
  border: 0;
  z-index: 10;

  @include media-breakpoint-down('xl') {
    display: none;
  }

  .icon-chevron {
    background-color: $white-alpha-7;

    &:hover {
      background-color: $white;
    }
  }

  &.ultra-slider-button-prev {
    left: -45px;
  }

  &.ultra-slider-button-next {
    right: -45px;
  }

  &.hidden {
    display: none !important;
  }
}

.ultra-slider-pagination {
  text-align: center;
  margin-top: 15px;

  &.hidden {
    display: none;
  }

  &.swiper-pagination-bullets-dynamic {
    margin: 15px auto auto;
    white-space: nowrap;

    .swiper-pagination-bullet {
      transform: scale(1);
    }
  }

  .swiper-pagination-bullet {
    width: 32px;
    height: 3px;
    background-color: $white-alpha-3;
    border-radius: 2px;
    margin: 0 5px;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: $ultra-purple;
    }

    &::before {
      content: ' ';
      display: inline-block;
      height: 20px;
      width: 100%;
    }
  }
}

.swiper-slide {
  opacity: 1;
  &.ultra-slideshow-slide-next {
    opacity: 0;
    transition: opacity 600ms ease-in;
  }
}

.theme-game-detail {
  .swiper-slide {
    width: 450px;
    height: 254px;

    @include media-breakpoint-up('md') {
      width: 482px;
      height: 272px;
    }

    @include media-breakpoint-up('xl') {
      width: 654px;
      height: 368px;
    }

    @media screen and (min-width: 3440px) {
      width: 974px;
      height: 520px;
    }

    &:not(.swiper-slide-active) {
      pointer-events: none;
    }

    &.swiper-slide-active {
      cursor: pointer;
    }
  }

  .ultra-slider-pagination {
    display: flex;
    z-index: 1;
    position: relative;
    background: $black-alpha-2;
    width: fit-content;
    margin: -14px auto 0;
    border-radius: 8px;
    padding: 2px;
  }
}

.theme-uniq-detail,
.theme-uniq-factory-detail,
.theme-token-preview {
  .swiper-slide {
    width: 590px;
    height: 332px;

    &:not(.swiper-slide-active) {
      pointer-events: none;
    }

    &.swiper-slide-active {
      cursor: pointer;
    }
  }
}

.external-pagination-carousel {
  .ultra-slider-pagination {
    margin: 27px auto 0;
  }
}

.bullets {
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    border-radius: 8px;
    margin: 0 4px;

    &.swiper-pagination-bullet-active {
      background-color: $white;
    }
  }
}

.inner-pagination {
  display: flex;
  place-content: center;
}
