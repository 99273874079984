@import '../../../../../../../assets/scss/non-compilable-tools';

ultra-dialog-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  background: $grey-dark-2-standard-bg;
  border: 1px solid $white-alpha-1;
  box-shadow: 0 remy(11) remy(15) $black-alpha-2, 0 remy(9) remy(46) $black-alpha-12,
    0 remy(24) remy(38) $black-alpha-12;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.dialog-header {
  padding: 1.25rem;
  border-bottom: 1px solid $white-alpha-1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dialog-content {
  padding: 1.25rem;
  flex: 1 1 auto;
}

.dialog-actions {
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $white-alpha-1;
}
